.app__section_title {
    display: flex;
    align-items: flex-end;
    width: fit-content;
}

.app__section_title_text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;

    color: #A85100;
}

.icon_claw_path1:before{
    color: #A85100!important;
    font-size: 20px;
}

.icon_claw_path2:before{
    color: #5A1E1C!important;
    font-size: 20px;
}

.icon_claw_path3:before{
    color: #5A1E1C!important;
    font-size: 20px;
}

.app__section_subtitle_text {
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 70px;
    /* identical to box height, or 233% */

    color: #E1B236;
}