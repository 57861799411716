.app__footer_wrapper {
    background-color: #966830;
    padding: 51px 3% 100px 3%;
    display: flex;
    justify-content: space-between;
}

.app__footer_left {
    flex: 0 0 50%;
}

.app__footer_right {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app__footer_links_wrapper {
    display: flex;
    flex-flow: wrap;
}


.app__footer_links_wrapper div {
    flex: 0 0 50%;
    margin: 15px 0;

    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    color: #FFFFFF;
}

.app__footer_social_wrapper {
    text-align: right;
}

.app__footer_social_wrapper div {
    margin: 9px 0;
}

.app__footer_social_wrapper i:before {
    font-size: 30px;
    color: #DADADA;
}

.app__footer_social_wrapper i:hover::before {
    color: white;
}

@media screen and (max-width: 690px) {
    .app__footer_wrapper {
        flex-direction: column;
    }

    .app__footer_right {
        align-items: flex-start;
        padding: 0 32px;
    }

    .app__footer_links_wrapper {
    }

    .app__footer_links_wrapper div {
        flex: 0 0 100%;
    }
}