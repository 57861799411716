
.sc-select-mobile-container {

    text-align: center;
}

.sc-select-mobile__control {
    background: #FFFAF5!important;

    padding: 0 15px;
    height: 54px;
    z-index: 10;
    border: none!important;
}

.sc-select-mobile__indicator svg{
    color: rgba(168, 81, 0, 1);
}

.sc-select-mobile__menu {
    margin-top: -10px!important;
}

.sc-select-mobile__control--is-focused,
.sc-select-mobile__control--menu-is-open {
    border: none!important;
    box-shadow: unset!important;
    border-radius: unset!important;
}

.sc-select-mobile__single-value {
    font-family: 'Noto Sans' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    color: #A85100!important;

}

.sc-select-mobile__value-container {
    padding-left: 20px!important;
}

.sc-select-mobile__indicator-separator {
    display: none;
}

.sc-select-mobile__dropdown-indicator {
    color: unset!important;
}

.sc-select-mobile__menu {
    /*margin-top: -10px!important;*/
    z-index: 0;
}

.sc-select-mobile__menu-list {
    /*padding: 20px 0 15px 0!important;*/
    background: #FFFAF5;
}

.sc-select-mobile__option {
    font-family: 'Noto Sans' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    margin: 10px 0;

    color: #000000!important;
}

.sc-select-mobile__option--is-focused,
.sc-select-mobile__option--is-selected {
    background: #FFFAF5!important;
}