.location_container {
    background: #FFFFFF;
    box-shadow: 0 4px 10px 5px rgba(168, 74, 0, 0.1);
    border-radius: 8px;

    padding: 15px 22px;
    width: 220px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.location_container:hover {
    box-shadow: 0 10px 14px 10px rgba(168, 74, 0, 0.1);
}

.location_title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #000000;
    flex: auto;
}

.location_icon:before {
    color: rgba(13, 6, 93, 1);
    font-size: 20px;
}

.location_address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #000000;

    display: flex;
    align-items: center;
    margin: 4px 0;
}

.location_phone {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    color: #000000;

    display: flex;
    align-items: center;
    margin: 4px 0;
}