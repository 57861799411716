.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    z-index: 999;
}

.overlay > div {
    margin: 20vh auto 0 auto;
}