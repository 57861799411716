.light_background {
    background-color: #FFDD83;
}

.dark_background {
    background-color: #FFD360;
}

.example_questions {
    text-align: center;
}

.example_heading {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 71px;
}

.question_card {
    width: 100%;
    padding: 23px;
    margin: 10px auto;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 4px 20px 10px rgba(90, 30, 28, 0.1);
    border-radius: 5px;
}

.question_card:hover {
    cursor: grab;
    box-shadow: 0 4px 20px 10px rgba(90, 30, 28, 0.2);
}

.question_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
}