
.app__navbar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-brown);
    /*opacity: 0.86;*/
    /*margin: 29px 5% 0 5%;*/
    /*border-radius: 8px;*/

    padding: 0.5rem 2rem;
    z-index: 3;
}

.app__navbar_left {
    display: flex;
}

.app__navbar_logo {
    align-items: center;
}

.app__navbar_links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    list-style: none;
}

.app__navbar_links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar_links li:hover {
    color: var(--color-grey);
}

.app__navbar_links span {
    font-weight: bold;
}

.app__navbar-end-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar_order {
    display: flex;
    /*justify-content: flex-end;*/
    align-items: center;
}

.app__navbar_order button {
    margin: 0 10px;
}

.app__navbar_order a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar_small_screen {
    display: none;
    color: var(--color-golden);
}

.app__navbar_small_screen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-black);
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar_small_screen_overlay .overlay_close {
    font-size: 57px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 40px;
    right: 40px;
}

.app__navbar_small_screen_links {
    list-style: none;
}

.app__navbar_small_screen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar_small_screen_links li:hover {
    color: var(--color-white);
}

.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}

.cart_count {
    font-size: 12px;
    background: #E1B236;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -20px;
}


@media screen and (max-width: 690px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar_small_screen {
        display: flex;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar_links {
        /*visibility: collapse;*/
        display: none;
    }

    .app__navbar_order button {
        background: none;
        border: none;
        box-shadow: none;
        color: var(--color-golden);
        padding: 11px 0 11px 0;
        margin: 0 5px;
    }

    .app__navbar_order button:hover {
        background-color: unset;
        color: white;
    }

    .app__navbar_small_screen {
        display: flex;
        justify-content: flex-end;
    }
}