body {
  -webkit-overflow-scrolling:touch;
}

#nprogress .bar {
  background: #E1B236 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #E1B236, 0 0 5px #E1B236!important;
}

#nprogress .spinner-icon {
  display: none;
  /*border-top-color: #E1B236!important;*/
  /*border-left-color: #E1B236!important;*/
}

.noscroll {
  overflow-y: hidden;
}

.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background-size: cover;
  background: url('../public/assets/images/ShakingTime.png') repeat fixed center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper-info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper-img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper-img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper-img img {
  width: 80%;
}

.custom__button {
  white-space: nowrap;
  background-color: var(--color-golden);
  color: var(--color-white);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  width: auto;
  height: auto;
  padding: 8px 22px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.custom__button:hover {
  background-color: #DDA30A;
}

.custom__button:focus {
  background: #E1B236;
  border: 2px solid #000000;
}

.custom__icon-outline-button {
  background-color: var(--color-golden);
  color: var(--color-white);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  font-size: 18px;
  line-height: 27px;
  width: 170px;
  height: auto;
  padding: 14px 0;
  border-radius: 10px;
  outline: none;
  cursor: pointer;

  text-align: center;

  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
}

.custom__icon-outline-button:hover {
  background: #DDA30A;
}

.custom__icon-outline-button:focus {
  background: #E1B236;
  border: 2px solid #000000;
}

.ubereats-theme {
  background-color: #3FC060;
}

.ubereats-theme:hover {
  background-color: #2E8B48;
}

.custom__purple-button {
  background-color: #0D065D;
  color: var(--color-white);
  font-family: var(--font-base);

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  height: 25px;
  padding: 2.5px 26px 2.5px 26px;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
}

.custom__purple-button:hover {
  background: #040036;
}

.custom__purple-button:focus {
  background: #0D065D;
  /* Color/Purple/40% */

  border: 2px solid #B6B4CE;
}

.custom__location_btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 59px;

  background: rgba(255, 255, 255, 0.85);
  /* Color/Purple/80% */

  border: 2px solid #3D387D;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  text-align: left;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #3D387D;

  padding: 18px 0 18px 36px;
}

.custom__location_btn i {
  margin-right: 16px;
}

.custom__location_btn i:before {
  font-size: 23px;
}

.custom__clickable_link {
  cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 45px;
}

.custom__reverse {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.custom__reverse_vertical {
  -moz-transform:scaleY(-1);
  -webkit-transform:scaleY(-1);
  -o-transform:scaleY(-1);
  transform:scaleY(-1);
  /*兼容IE*/
  filter:FlipV;
}

.swiper-pagination-bullet-active {
  background: var(--color-golden);
}

.cs-message-input__content-editor,
.cs-message-input__content-editor-wrapper,
.cs-message--incoming .cs-message__content {
  background-color: var(--color-lightgray) !important;
}

.cs-typing-indicator__dot {
    background-color: var(--color-gray) !important;
}

.cs-button--send,
.cs-typing-indicator__text {
  color: var(--color-gray) !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: var(--color-lightgray) !important;
}

.ps__thumb-y {
  background-color: var(--color-gray) !important;
}

.thumb-slides {
}

.thumb-slides .swiper-slide {
  cursor: pointer;
  opacity: 0.4;
}

.thumb-slides .swiper-slide {
  opacity: 0.4;
}

.thumb-slides .swiper-slide-thumb-active {
  opacity: 1;
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper-img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper-img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper-img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
