
.container {
    width: 75%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title_text {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */


    color: #0D065D;
}

.close_icon:before {
    color: #0D065D;
    font-size: 20px;
}

.content_wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.left_container {
    background: #F4E9DB;
    border-radius: 4px;
    padding: 20px 13px;
}

.right_container {
    width: 100%;
    height: 50vh;
    padding: 0 5%;
    overflow-y: auto;
}

.left_option {
    white-space: nowrap;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: #3E3E3E;
    padding: 6px 13px;
    margin: 6px 0;
}

.left_option_selected {
    background: #E1B236;
    border-radius: 8px;

    color: #FFFFFF;
}

.right_title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #000000;

    margin: 20px 0;
}

.right_locations_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px 20px;
    justify-items: center;

}

@media screen and (max-width: 690px) {

    .container {
        margin: 0!important;
        padding: 10px;
        border-radius: 0;
        width: 100%;
        height: 100%;
    }
    .right_locations_container {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .content_wrapper {
        flex-direction: column;
        justify-content: flex-start;
    }
    .left_container {
        background:unset;
        display: flex;
        flex-direction: row;
        flex-flow: nowrap;
        overflow-x: auto;
    }
    .left_container div {
        width: auto;
    }
    .right_container {
        height: 100%;
        /* padding: 0 1%; */
        overflow-y: auto;
    }
    .right_locations_container div {
        width: 90% !important;
        height: auto;
    }
    .right_locations_container div p:first-child {
        padding-left: 5px;
    }
    .right_title {
        display: none;
    }
}
