.container {
    background: rgba(255, 255, 255);
    border-radius: 10px;
    padding: 15px;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0!important;
}

.swiper {
    width: 63vh;
    height: 80vh;
}

@media screen and (max-width: 768px) {
    .swiper {
        width: 90vw;
        height: 127vw;
    }
}
