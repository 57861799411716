.chat_component {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    bottom: 20px;
    right: 20px;
    border-radius: 5px;
    z-index: 998;
}

.chat_popup {
    position: relative;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    font-family: var(--font-alt);
    font-size: 16px;
}

.chat_popup:before {
    content: "";
    position: absolute;
    bottom: -9px;
    right: 10px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #f8f8f8 transparent;
}

.chat_body {
    max-width: calc(100vw - 40px);
    border: 1px solid #ccc;
    border-radius: 5px;
}

.chat_icon {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #f8f8f8;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 998;
}

.chat_icon:hover {
    background-color: #eaeaea;
}

.chat_icon svg {
    width: 20px;
    height: 20px;
    color: #555;
}
