
.sc-select-yellow-container {
    /*border: 1px solid #0D065D;*/
    box-sizing: border-box;
    border-radius: 10px;

    text-align: start;
}

.sc-select-yellow__control {
    background: #FAFAFA!important;

    padding: 0 15px;
    height: 54px;
    z-index: 10;
}

.sc-select-yellow__input-container::before {
    content: '\e915';
    font-size: 25px;
    position: relative;
    left: -25px;
}
.sc-select-yellow__single-value {
    font-family: Noto Sans!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 20px!important;
    /* identical to box height, or 125% */

    color: #0D065D!important;
}

.sc-select-yellow__value-container {
    padding-left: 20px!important;
}

.sc-select-yellow__indicator-separator {
    display: none;
}

.sc-select-yellow__dropdown-indicator {
    color: unset!important;
}

.sc-select-yellow__menu {
    margin-top: -10px!important;
    z-index: 10 !important;
}

.sc-select-yellow__menu-list {
    padding: 20px 0 15px 0!important;
    background: #FAFAFA;
    border: 1px solid #0D065D;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
}

.sc-select-yellow__option {
    font-family: Noto Sans!important;
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 16px!important;
    line-height: 22px!important;
    /* identical to box height */

    color: #3D3D3D!important;

    padding: 5px 0 5px 40px!important;
}

.sc-select-yellow__option--is-focused,
.sc-select-yellow__option--is-selected {
    background: #ECECEC!important;
}
