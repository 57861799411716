.container {

}

.title {
    text-align: center;
}

.title span[class*=title_text] {
    font-size: 25px;
}

.title span[class*=subtitle_text] {
    font-size: 18px;
}

.title * {
    margin: 2px auto;
}

.content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.region {
    width: 100%;
    text-align: center;
    font-size: 17px;
    margin-bottom: 8px;
}

@media screen and (max-width: 690px) {
    .content {
        display: unset;
        text-align: center;
    }
}